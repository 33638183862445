import React from 'react';
import { graphql } from 'gatsby';
import {
  OrdnaQuery as ForetagQuery,
  ContentfulCard,
  ContentfulDialogContent,
} from '../../graphql-types';

import SEO from '@components/seo';
import Layout from '@components/Layout/Layout';
import Card, { CardImage } from '@components/Card';
import Grid, { Row } from '@components/Grid';
import Container from '@components/Container';

import useContentfulImage from '@src/hooks/useContentfulImage.hook';
import * as styles from './foretag.module.scss';
import ProductHero from '@src/components/ProductHero';
import { navigate } from 'gatsby';
import Footer from '@src/components/Footer/Footer';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';
import SwipeableCard from '@src/components/SwipeableCard';
import { StaticImage } from 'gatsby-plugin-image';
import BlocketLogo from '@icons/2.0/companies/blocket.svg';
import SkaffaHundLogo from '@icons/2.0/companies/skaffahund.svg';
import BussgodsLogo from '@icons/2.0/companies/bussgods.svg';
import PlickLogo from '@icons/2.0/companies/plick.svg';
import ContentfulDialog from '@src/components/Dialog/ContentfulDialog';
import useDialogRoute from '@components/Dialog/useDialogRoute.hook';
import { Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AppRatings from '@src/components/Home/AppRatings';

type ForetagProps = {
  data: ForetagQuery;
};

function Foretag({ data }: ForetagProps) {
  const {
    contentfulPage: {
      headLine,
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
      heroImage,
      pageContent,
    },
  } = data;

  const heroImg = useContentfulImage(heroImage);

  const heroCard = pageContent[0] as ContentfulCard;
  const heroCardImg = useContentfulImage(heroCard.backgroundImage);

  const easyIntegrationCard = pageContent[1] as ContentfulCard;
  const easyIntegrationCardImg = useContentfulImage(
    easyIntegrationCard.backgroundImage,
  );

  const paymentsCard = pageContent[2] as ContentfulCard;
  const paymentsCardImg = useContentfulImage(paymentsCard.backgroundImage);

  const paymentsDialog = useDialogRoute('Betalalternativ');
  const paymentsDialogContent = pageContent[3] as ContentfulDialogContent;

  const flexiblePaymentCard = pageContent[4] as ContentfulCard;
  const flexiblePaymentCardImg = useContentfulImage(
    flexiblePaymentCard.backgroundImage,
  );

  const bokaDemoCard = pageContent[5] as ContentfulCard;
  const bokaDemoCardImg = useContentfulImage(bokaDemoCard.backgroundImage);

  const marketplacePaymentCard = pageContent[6] as ContentfulCard;
  const marketplacePaymentCardImg = useContentfulImage(
    marketplacePaymentCard.backgroundImage,
  );

  const payPortalCard = pageContent[7] as ContentfulCard;
  const payPortalCardImg = useContentfulImage(payPortalCard.backgroundImage);

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const navLinks = [
    {
      name: 'Rocker Pay',
      href: '/pay/foretag',
    },
    {
      name: 'Prissättning',
      href: '/pay/prissattning',
    },
  ];

  const testimonialElement = (
    <SwipeableCard
      theme="dark-purple"
      cardItems={[
        {
          title: '“Rocker säkerställer trygghet för våra användare”',
          text: 'Louise Helmre Livijn, skaffahund.se.',
          imgPath: (
            <StaticImage
              src="../../images/2.0/SkaffaHund.png"
              alt="Skaffa Hund"
              width={100}
            />
          ),
        },
      ]}
    />
  );

  const easyIntegrationElement = (
    <Card
      title={easyIntegrationCard.title}
      text={easyIntegrationCard.subtitle}
      theme="soft"
    >
      <CardImage imageData={easyIntegrationCardImg} />
    </Card>
  );

  const paymentsElement = (
    <Card
      title={paymentsCard.title}
      text={paymentsCard.subtitle}
      cta={paymentsCard.linkText}
      onClick={paymentsDialog.openDialog}
      theme="soft"
    >
      <CardImage imageData={paymentsCardImg} />
    </Card>
  );

  const flexiblePaymentsElement = (
    <Card
      title={flexiblePaymentCard.title}
      text={flexiblePaymentCard.subtitle}
      theme="soft"
      noBorder
    >
      <CardImage imageData={flexiblePaymentCardImg} />
    </Card>
  );

  const bookDemoElement = (
    <Row>
      <Card
        title={bokaDemoCard.title}
        text={bokaDemoCard.subtitle}
        cta={bokaDemoCard.linkText}
        onClick={() => navigate(heroCard.linkPath)}
        backdropImage={bokaDemoCardImg}
        wide
        theme="purple"
      />
    </Row>
  );
  return (
    <Layout theme="white" subNavLinks={navLinks}>
      <SEO title={headLine} />
      <Container>
        <ProductHero
          title="Säkra betalningar med Rocker Pay"
          body={pageHeaderDescription}
          image={heroImg}
        >
          <PrimaryButton
            color="purple"
            href="https://airtable.com/appSfUxboHmAtHoqE/shrTq9fLMpK8D3yQY"
          >
            Kontakta vårt team
          </PrimaryButton>

          <div className={styles.companiesLikeText}>
            Företag som gillar Rocker Pay:
            <div className={styles.companiesLogoContainer}>
              <BlocketLogo />
              <PlickLogo />
              <SkaffaHundLogo />
              <BussgodsLogo />
            </div>
          </div>
          <AppRatings hideAppStore={true} hidePlayStore={true} />
        </ProductHero>
        <Box sx={{ width: '100%' }}>
          <Tabs
            variant="scrollable"
            allowScrollButtonsMobile
            className={styles.tabs}
            value={tabValue}
            onChange={handleTabChange}
          >
            <Tab label="Enkel Checkout" />
            <Tab label="Betallösning" />
            <Tab label="Portal" />
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}></Box>
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <Grid spacedRows>
            <Row>
              <Card
                title={heroCard.title}
                text={heroCard.subtitle}
                cta={heroCard.linkText}
                ctaHref={heroCard.linkPath}
                contentfulImg={heroCardImg}
                wide
                theme="soft-2"
              />
            </Row>
            {easyIntegrationElement}
            {paymentsElement}
            {testimonialElement}
            {flexiblePaymentsElement}
            {bookDemoElement}
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Grid spacedRows>
            <Row>
              <Card
                title={marketplacePaymentCard.title}
                text={marketplacePaymentCard.subtitle}
                cta={marketplacePaymentCard.linkText}
                ctaHref={marketplacePaymentCard.linkPath}
                contentfulImg={marketplacePaymentCardImg}
                wide
                theme="soft-2"
              />
            </Row>
            {easyIntegrationElement}
            {paymentsElement}
            {testimonialElement}
            {flexiblePaymentsElement}
            {bookDemoElement}
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Grid spacedRows>
            <Row>
              <Card
                title={payPortalCard.title}
                text={payPortalCard.subtitle}
                cta={payPortalCard.linkText}
                ctaHref={payPortalCard.linkPath}
                backdropImage={payPortalCardImg}
                wide
                theme="soft-2"
              />
            </Row>
            {easyIntegrationElement}
            {paymentsElement}
            {testimonialElement}
            {flexiblePaymentsElement}
            {bookDemoElement}
          </Grid>
        </TabPanel>
        <ContentfulDialog
          visible={paymentsDialog.isOpen}
          contentfulData={paymentsDialogContent}
          onClose={paymentsDialog.closeDialog}
        />
      </Container>
    </Layout>
  );
}

export default Foretag;

export const query = graphql`
  query Foretag($locale: String) {
    contentfulPage(
      pageUrl: { eq: "/pay/foretag" }
      node_locale: { eq: $locale }
    ) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      heroImage {
        title
        gatsbyImageData(width: 1040)
      }
      pageContent {
        ... on ContentfulCard {
          id
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
          linkText
          linkPath
        }
        ... on ContentfulDialogContent {
          id
          name
          textContent {
            textContent
          }
          questions {
            answer {
              answer
            }
            question
          }
          heading
          headerImage {
            title
            gatsbyImageData(width: 630)
          }
          ctaHref
          ctaText
          extraContent {
            ... on ContentfulTextList {
              id
              listName
              advancedTextListItems {
                icon
                text
              }
            }
            ... on ContentfulTextSection {
              title
              content {
                content
              }
            }
            ... on ContentfulFaqSection {
              id
              questions {
                answer {
                  answer
                }
                question
              }
              title
              contactEmail
            }
          }
        }
      }
    }
  }
`;
