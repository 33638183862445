import Icon from '@components/Icon/Icon';

import * as styles from './AppRatings.module.scss';
import useAppStoreRatings from '@src/hooks/useAppStoreRatings';
import React from 'react';
import classNames from 'classnames';
import useTrustpilotRatings from '@src/hooks/useTrustpilotRatings.hook';
import TrustpilotLogo from '@src/icons/2.0/TrustpilotLogo.svg';
import useIsMobile from '@src/hooks/useIsMobile.hook';

type AppRatingsProps = React.HTMLProps<HTMLDivElement> & {
  className?: string;
  hidePlayStore?: boolean;
  hideAppStore?: boolean;
  hideTrustPilot?: boolean;
};

function AppRatings({
  className,
  hidePlayStore,
  hideAppStore,
  hideTrustPilot,
}: AppRatingsProps) {
  const { rating: appStoreRating, appStoreUrl } = useAppStoreRatings();
  const playStoreLink =
    'https://play.google.com/store/apps/details?id=com.rocker.app';
  const playStoreRating = 4.5;

  const formatRating = (r: number) => {
    let res = `${r.toFixed(1)}`.replace('.', ',');
    if (`${r}`.indexOf('.') === -1) {
      res += ',0';
    }
    return res;
  };

  const { rating, url } = useTrustpilotRatings();
  const isMobile = useIsMobile();

  const classes = classNames({
    [styles.ratingsContainer]: true,
    [className]: className,
  });

  return (
    <div className={classes}>
      {!hideAppStore && (
        <a
          className={styles.section}
          href={appStoreUrl}
          target="_blank"
          rel="noreferrer"
        >
          <div className={styles.logoAndScore}>
            <Icon name="AppleLogo" width={24} height={24} aria-label="Apple" />
            <span className={styles.score}>{formatRating(appStoreRating)}</span>
          </div>
          {!isMobile && <span className={styles.label}>App Store</span>}
        </a>
      )}
      {!hidePlayStore && (
        <a
          className={styles.section}
          href={playStoreLink}
          target="_blank"
          rel="noreferrer"
        >
          <div className={styles.logoAndScore}>
            <Icon
              name="GooglePlay"
              width={24}
              height={24}
              aria-label="Google Play"
            />
            <span className={styles.score}>
              {formatRating(playStoreRating)}
            </span>
          </div>
          {!isMobile && <span className={styles.label}>Google Play</span>}
        </a>
      )}
      {!hideTrustPilot && (
        <a
          className={styles.section}
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          <div className={styles.logoAndScore}>
            <TrustpilotLogo width="24" height="24" alt="Trustpilot" />
            <span className={styles.score}>
              {`${rating}`.replace('.', ',')}
            </span>
          </div>
          {!isMobile && <span className={styles.label}>Trustpilot</span>}
        </a>
      )}
    </div>
  );
}

export default AppRatings;
