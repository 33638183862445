// extracted by mini-css-extract-plugin
export var bottomContainer = "foretag-module--bottomContainer--85fbb";
export var cash1 = "foretag-module--cash1--a75f5";
export var cash2 = "foretag-module--cash2--0e66f";
export var cash3 = "foretag-module--cash3--c5cc4";
export var companiesLikeText = "foretag-module--companiesLikeText--024d9";
export var companiesLogoContainer = "foretag-module--companiesLogoContainer--b173e";
export var downloadBadgesContainer = "foretag-module--downloadBadgesContainer--e40c7";
export var gridContainer = "foretag-module--gridContainer--b312b";
export var receiptContainer = "foretag-module--receiptContainer--2beed";
export var tabs = "foretag-module--tabs--c4350";
export var textHero = "foretag-module--textHero--a601c";