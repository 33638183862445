import { useEffect, useState } from 'react';
import { oc } from 'ts-optchain';

type AppRating = {
  rating: number;
  url: string;
};

const useTrustpilotRatings = (): AppRating => {
  const [ratings, setRatings] = useState({
    rating: 4.5,
    url: 'https://www.trustpilot.com/review/rocker.com',
  });

  const businessUnitId = '5e1db5ace2e67b0001bb8d2d';
  const apiKey = 'hLRWQ9qHxqnA2U5GF6lM4zjG01dqsDez';
  useEffect(() => {
    const isLocal =
      typeof window !== 'undefined' && 'localhost' === window.location.hostname;

    if (isLocal) {
      return;
    }

    const fetchData = async () => {
      const res = await fetch(
        `https://api.trustpilot.com/v1/business-units/${businessUnitId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            apiKey: apiKey,
          },
        },
      );

      const json = await res.json();
      if (oc(json).score.trustScore()) {
        setRatings({
          rating: json.score.trustScore,
          url: 'https://www.trustpilot.com/review/rocker.com',
        });
      } else {
        // No score from trustpilot
      }
    };

    fetchData();
  }, []);

  return ratings;
};

export default useTrustpilotRatings;
